exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-essays-tsx": () => import("./../../../src/pages/essays.tsx" /* webpackChunkName: "component---src-pages-essays-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-web-agency-alternative-tsx": () => import("./../../../src/pages/web-agency-alternative.tsx" /* webpackChunkName: "component---src-pages-web-agency-alternative-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-pages-your-new-wordpress-developer-tsx": () => import("./../../../src/pages/your-new-wordpress-developer.tsx" /* webpackChunkName: "component---src-pages-your-new-wordpress-developer-tsx" */),
  "component---src-templates-blog-essay-tsx": () => import("./../../../src/templates/blog-essay.tsx" /* webpackChunkName: "component---src-templates-blog-essay-tsx" */),
  "component---src-templates-newsletter-issue-tsx": () => import("./../../../src/templates/newsletter-issue.tsx" /* webpackChunkName: "component---src-templates-newsletter-issue-tsx" */),
  "component---src-templates-work-project-tsx": () => import("./../../../src/templates/work-project.tsx" /* webpackChunkName: "component---src-templates-work-project-tsx" */)
}

